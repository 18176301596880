import React from "react";
import { Link } from "gatsby";
import styles from "./footer.module.css";

const Footer = () => {
  return (
    <footer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "40px 0px"
        }}
        className={styles.container}
      >
        <div className={styles.footerSection}>
          <ul>
            <li>
              <Link to="/crm-dla-malej-firmy/">CRM dla małej firmy</Link>
            </li>
            <li>
              <Link to="/crm-dla-sredniej-firmy/">CRM dla średniej firmy</Link>
            </li>
             <li>
              <Link to="/crm-dla-duzej-firmy/">CRM dla dużej firmy</Link>
            </li>
            <li>
              <Link to="/crm/">CRM</Link>
            </li>
            <li>
              <Link to="/system-crm/">System CRM</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <ul>
           <li>
              <Link to="/o-nas/">O nas</Link>
            </li>
            <li>
              <Link to="/rodo/">RODO</Link>
            </li>
            <li>
              <Link to="/bezpieczenstwo-danych/">Bezpieczeństwo</Link>
            </li>
            <li>
              <Link to="/regulamin/">Regulamin</Link>
            </li>
            <li>
              <Link to="/pomoc/">Pomoc</Link>
            </li>
          </ul>
        </div>
        <div id={styles.address} className={styles.footerSection}>
          <p>Questy</p>
          <p>ul. Głogowska 31/33</p>
          <p>60-702 Poznań</p>
          <p>
              <a style={{
          color: "black",
        }}
                className={styles.phoneNum}
                href="tel:+48506504540"
                title="+48 506 504 540"
              >
                +48 506 504 540
              </a>
            </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
