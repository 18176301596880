import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  Stack,
  Text,
} from "@chakra-ui/core";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { EMAIL_REGEX } from "../../consts";
import MainButton from "./MainButton";
import StepBox from "./StepBox";
import StyledInput from "./StyledInput";
import SystemName from "./SystemName";
import StepHeader from "./StepHeader";

const SYSTEM_NAME_REGEX = /^[a-z0-9]+$/i;

const Step1 = ({
  primaryColor,
  secondaryColor,
  currentStep,
  goToNextStep,
  checkName,
  register,
  unregister,
  setValue,
  errors,
  triggerValidation,
  setFormState,
  getValues,
  stepHeaders,
  control,
}) => {
  const [systemName, setSystemName] = useState("");

  const handleValidateStep = async (e) => {
    await triggerValidation();
    if (Object.keys(errors).length === 0) {
      setFormState((formState) => ({
        ...formState,
        ...getValues(),
      }));
      goToNextStep();
    }

    return;
  };

  if (currentStep !== 1) {
    return null;
  }

  return (
    <StepBox>
      <Stack direction="column" justifyContent="space-between" h="full">
        {stepHeaders?.step1}
        <Stack spacing={4}>
          <FormControl isInvalid={errors?.systemName}>
            <FormLabel fontSize="lg" htmlFor="systemName">
              Skrócona nazwa firmy
            </FormLabel>
            <Controller
              control={control}
              name="systemName"
              rules={{
                minLength: { value: 1, message: "Nazwa systemu jest wymagana" },
                pattern: SYSTEM_NAME_REGEX,
                required: {
                  value: true,
                  message: "Nazwa systemu jest wymagana",
                },
                validate: async (value) => await checkName(value),
              }}
              render={(props) => (
                <StyledInput
                  type="text"
                  id="systemName"
                  placeholder="np. questy"
                  {...props}
                  onChange={(e) => {
                    if (
                      SYSTEM_NAME_REGEX.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setSystemName(e.target.value);
                      props.onChange(e);
                    }
                  }}
                />
              )}
            />

            <FormErrorMessage>
              {errors.systemName && errors.systemName.message}
            </FormErrorMessage>
            {!errors.systemName && systemName && (
              <SystemName name={systemName}></SystemName>
            )}
          </FormControl>
          <FormControl isInvalid={errors.email}>
            <FormLabel fontSize="lg" htmlFor="email">
              Służbowy adres e-mail
            </FormLabel>
            <StyledInput
              ref={register({
                required: {
                  value: true,
                  message: "Adres e-mail jest wymagany",
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Musisz wpisać poprawny adres e-mail",
                },
              })}
              type="email"
              id="email"
              name="email"
              placeholder="np. nowak@questy.pl"
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.password}>
            <FormLabel fontSize="lg" htmlFor="password">
              Hasło do systemu
            </FormLabel>
            <StyledInput
              ref={register({
                required: {
                  value: true,
                  message: "Hasło jest wymagane i musi mieć min. 8 znaków",
                },
                minLength: {
                  value: 8,
                  message: "Hasło powinno mieć min. 8 znaków",
                },
              })}
              type="password"
              id="password"
              name="password"
              placeholder="hasło powinno mieć min. 8 znaków"
            />
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.consent}>
            <Checkbox
              name="consent"
              ref={register({
                required: {
                  value: true,
                  message: "Zgoda jest wymagana",
                },
              })}
              borderColor="rgb(226, 232, 240)"
              size="lg"
            >
              <Text as="span" fontSize="xs">
                Zakładając konto{" "}
                <strong>
                  <Link isExternal href="https://quickcrm.pl/regulamin/">
                    akceptuję regulamin
                  </Link>
                </strong>{" "}
                i{" "}
                <strong>
                  <Link
                    isExternal
                    href="https://quickcrm.pl/umowa-powierzenia/"
                  >
                    umowę powierzenia przetwarzania danych osobowych
                  </Link>
                </strong>
                .
              </Text>
            </Checkbox>
            <FormErrorMessage>
              {errors.consent && errors.consent.message}
            </FormErrorMessage>
          </FormControl>
        </Stack>
        <MainButton
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          onClick={handleValidateStep}
        >
          Wypróbuj teraz
        </MainButton>
      </Stack>
    </StepBox>
  );
};

export default Step1;
