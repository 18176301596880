import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Text, Box } from "@chakra-ui/core";
import MultiStepForm from "./MultiStepFormContainer";
import StepHeader from "./StepHeader";

const LOGO_QUERY = graphql`
  query {
    logo: file(relativePath: { eq: "QuickCRM-prosty-CRM-dla-dynamicznych-zespolow.png" }) {
      childImageSharp {
        fluid(maxWidth: 330, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const MultiStepFormButton = ({ buttonProps }) => {
  const data = useStaticQuery(LOGO_QUERY);

  return (
    <MultiStepForm
      buttonProps={buttonProps}
      primaryColor="#64ae60"
      secondaryColor="#64ae60"
      progressBarColor="green" // specjalny kolor z motywu
      logo={<Img fluid={data.logo.childImageSharp.fluid}></Img>}
      systemType="quickcrm"
      stepHeaders={{
        step1: (
          <StepHeader
            headerText="Wypróbuj QuickCRM"
            subText="Dostęp w kilka sekund. Bez zobowiązań. 30 dni za darmo."
          ></StepHeader>
        ),
        step2: (
          <StepHeader
            headerText="Dokończ konfigurację"
            subText="Którą wersję wybierasz?"
          ></StepHeader>
        ),
        step3: (
          <StepHeader
            headerText="Wypróbuj QuickCRM"
            subText="Dostęp w kilka sekund. Bez zobowiązań. 30 dni za darmo."
          ></StepHeader>
        ),
      }}
      industries={[
        {
          text: "Maszyny i urządzenia",
          value: "Maszyny i urządzenia",
        },
        {
          text: "Artykuły biurowe/szkolne",
          value: "Artykuły biurowe/szkolne",
        },
        {
          text: "Artykuły kosmetyczne",
          value: "Artykuły kosmetyczne",
        },
        {
          text: "Artykuły weterynaryjne",
          value: "Artykuły weterynaryjne",
        },
        {
          text: "Audio-video",
          value: "Audio-video",
        },
        {
          text: "Chemia gospodarcza",
          value: "Chemia gospodarcza",
        },
        {
          text: "Gastronomiczna",
          value: "Gastronomiczna",
        },
        {
          text: "Medyczna",
          value: "Medyczna",
        },
        {
          text: "Prawnicza",
          value: "Prawnicza",
        },
        {
          text: "Księgowość",
          value: "Księgowość",
        },
        {
          text: "Rolnicza",
          value: "Rolnicza",
        },
        {
          text: "Sektor IT",
          value: "Sektor IT",
        },
        {
          text: "Inna",
          value: "Inna",
        },
      ]}
      systemVersions={[
        {
          name: (
            <Text>
              <Text as="span" fontWeight="bold" textTransform="uppercase">
                Start
              </Text>{" "}
              (Zadania, Szanse Sprzedaży, Klienci)
            </Text>
          ),
          code: "start",
        },
        {
          name: (
            <Text>
              <Text as="span" fontWeight="bold" textTransform="uppercase">
                Standard
              </Text>{" "}
              (Start + Komunikator + Uprawnienia)
            </Text>
          ),
          code: "std",
        },
        {
          name: (
            <Text>
              <Text as="span" fontWeight="bold" textTransform="uppercase">
                PRO
              </Text>{" "}
              (Standard + Integracja z GUS + Eksport osób)
            </Text>
          ),
          code: "pro",
        },
      ]}
    ></MultiStepForm>
  );
};

export default MultiStepFormButton;
